<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				content:{}
			}
		},
		computed:{
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: this.web.lbl_privacy_policy,
					link: { name: 'PrivacyPolicy' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});

			this.getApi()
		},
		methods: {
			getApi() {
				this.$root.topProgress.done()
				Gen.apirest("/static-content", {id:8},(err,resp)=>{
					if(err) console.log(err)
					this.content = resp.data
					console.log(resp.data)
				})
			},
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
		<section class="section nobottommargin notopmargin">
			<div class="container">
				<BreadCrumb :data="breadCrumb"></BreadCrumb>
				<div class="row justify-content-center">
					<div class="col-md-10">
						<div class="box-el wrap-policy" v-html="content.description">
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>